<template>
    <div class="finalize">
        <navbar/>
        <loader v-show="loading"></loader>
        
        <div class="finalize__content" v-show="cart.length>0 && !loading">
            <div class="finalize__content__breadcrumbs">
                <router-link to="/koszyk">Koszyk</router-link>
                <i class="fi-rr-angle-small-right"></i>
                Nowe zamówienie
            </div>
            <stepper :statuses="stepperStatus"></stepper>
            <div class="finalize__content__container">
                <div class="finalize__content__container__half" v-show="step === 1">
                    <div class="finalize__content__container__half__texts">
                        <div class="cart__content__texts__header">
                            <h4>Informacje Podstawowe</h4>
                        </div>
                        <div class="finalize__content__container__half__subheader">
                            <h3>Wypełnij formularz, aby potwierdzić zakup.</h3>
                        </div>
                    </div>
                    <div class="finalize__content__container__half__isCompany">
                        <div class="finalize__content__container__half__isCompany__checkContainer">
                                <label class="switch">
                                <input hidden type="checkbox" v-model="companyMode" @change="changeCustomerType()"/>
                                <span class="slider round"></span>
                                </label>
                        </div>
                        <div> {{typeOfCustomer}}</div>
                    </div>
                    <transition name="fade">
                    <div class="finalize__content__container__half__inputSection" v-show="companyMode">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Nazwa Firmy
                        </div>
                        <input class="--textInput" v-model="newOrder.company" placeholder="Nazwa Firmy" type="text">
                    </div>
                    </transition>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.company">
                           Nazwa firmy jest za krótka. Sprawdź ją ponownie.
                        </div>
                    </transition>
                    <transition name="fade">
                    <div class="finalize__content__container__half__inputSection" v-show="companyMode">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            NIP
                        </div>
                        <input class="--textInput" v-model="newOrder.taxNum" placeholder="NIP (Format: XXXXXXXXXX)" v-on:blur="getDataByNIP()" type="text">
                        <div class="additionalComment">*Pozostałe dane do faktury zostaną pobrane automatycznie po numerze NIP.</div>
                        <transition name="fade">
                            <div class="downloadingData" v-show="showLoadingNip">
                                <div id="loading-indicator" style="width: 15px; height: 15px;" role="progressbar" class="MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate"><svg viewBox="22 22 44 44" class="MuiCircularProgress-svg"> <circle cx="44" cy="44" r="20.2" fill="none" stroke-width="3.6" class="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate"></circle></svg></div>
                                <div class="downloadingData__text">Pobieram dane..</div>
                            </div>
                        </transition>
                        <transition name="fade">
                            <div class="finalize__content__container__half__inputSection" v-show="companyMode && showDownloadedByNip">
                                <span class="addressTitle">Adres na fakturze:</span>
                                <span class="addressValue">{{downloadedByNip.address}}</span>
                                <span class="editValue" @click="editDataDownloadedByNip()">Zmień</span>
                            </div>
                        </transition>
                    </div>
                    </transition>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.taxNum">
                           Sprawdź swój NIP jeszcze raz.
                        </div>
                    </transition>
                    <transition name="fade">
                        <div class="finalize__content__container__half__inputSection" v-show="companyMode && showDownloadedByNip == false && editLoadedByNip">
                            <div class="finalize__content__container__half__inputSection__sectionName">
                                Adres na fakturze
                            </div>
                            <input class="--textInput" v-model="newOrder.taxAddress" placeholder="Adres na fakturze" type="text">
                        </div>
                    </transition>
                    <transition name="fade">
                        <div class="finalize__content__container__half__inputSection" v-show="companyMode && showDownloadedByNip == false && editLoadedByNip">
                            <div class="finalize__content__container__half__inputSection__sectionName">
                                Kod pocztowy na fakturze
                            </div>
                            <input class="--textInput" v-model="newOrder.taxPostCode" placeholder="Kod pocztowy na fakturze" type="text">
                        </div>
                    </transition>
                    <transition name="fade">
                        <div class="finalize__content__container__half__inputSection" v-show="companyMode && showDownloadedByNip == false && editLoadedByNip">
                            <div class="finalize__content__container__half__inputSection__sectionName">
                                Miejscowość na fakturze
                            </div>
                            <input class="--textInput" v-model="newOrder.taxCity" placeholder="Miejscowość na fakturze" type="text">
                        </div>
                    </transition>
                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Imię
                        </div>
                        <input class="--textInput" v-model="newOrder.name" placeholder="Imię" type="text">
                    </div>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.name">
                           Zawartość pola imię jest zbyt krótka.
                        </div>
                    </transition>
                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Nazwisko
                        </div>
                        <input class="--textInput" v-model="newOrder.surname" placeholder="Nazwisko" type="text">
                    </div>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.surname">
                           Zawartość pola nazwisko jest zbyt krótka.
                        </div>
                    </transition>
                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            E-mail
                        </div>
                        <input class="--textInput" v-model="newOrder.mail" placeholder="Adres mailowy" type="text">
                    </div>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.mail">
                           E-mail jest nieprawidłowy. Sprawdź go jeszcze raz.
                        </div>
                    </transition>
                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Numer Telefonu
                        </div>
                        <input class="--textInput" v-model="newOrder.phone" placeholder="Numer telefonu" type="text">
                    </div>
                    <div class="finalize__content__container__half__error" v-show="errors.phone">
                        Sprawdź czy numer telefonu, który podałeś jest poprawny.
                    </div>
                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Dodatkowe informacje
                        </div>
                        <input class="--textInput" v-model="newOrder.comment" maxlength="500" placeholder="Komentarz" type="text">
                    </div>
                    <div class="finalize__content__container__half__rules">
                        <input type="checkbox" v-model="rulesAccepted"> Akceptuję <router-link to="/regulamin" target="_blank"> regulamin </router-link> i <router-link to="/politykaprywatnosci" target="_blank"> politykę prywatności. </router-link>
                    </div>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show='errors.rules'>
                            Regulamin musi zostać zaakceptowany, aby kontynuować.
                        </div>
                    </transition>

                </div>

                
                <div class="finalize__content__container__half" v-show="step === 2">
                    <div class="finalize__content__container__half__texts">
                        <div class="cart__content__texts__header">
                            <h4>Dane dostawy</h4>
                        </div>
                        <div class="finalize__content__container__half__subheader">
                            <h3>Wypełnij formularz dostawy, aby przejść do płatności.</h3>
                        </div>
                        <div class="finalize__content__container__half__previousStep">

                        </div>
                    </div>
                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Imię
                        </div>
                        <input class="--textInput" v-model="newOrder.deliveryName" placeholder="Imię" type="text">
                    </div>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.deliveryName">
                           Zawartość pola imię jest zbyt krótka.
                        </div>
                    </transition>
                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Nazwisko
                        </div>
                        <input class="--textInput" v-model="newOrder.deliverySurname" placeholder="Nazwisko" type="text">
                    </div>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.deliverySurname">
                           Zawartość pola nazwisko jest zbyt krótka.
                        </div>
                    </transition>
                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Adres
                        </div>
                        <input class="--textInput" v-model="newOrder.address" placeholder="Ulica" type="text">
                    </div>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.address">
                            Wprowadzony adres jest za krótki.
                        </div>
                    </transition>

                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Miasto
                        </div>
                        <input class="--textInput" placeholder="Miasto" type="text" v-model="newOrder.city">
                    </div>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.city">
                            Nazwa miejscowości jest za krótka.
                        </div>
                    </transition>
                    <div class="finalize__content__container__half__inputSection">
                        <div class="finalize__content__container__half__inputSection__sectionName">
                            Kod Pocztowy
                        </div>
                        <input class="--textInput" placeholder="Kod Pocztowy" type="text" v-model="newOrder.postCode">
                    </div>
                    <transition name="fade">
                        <div class="finalize__content__container__half__error" v-show="errors.postCode">
                            Kod pocztowy jest niepoprawny. Wymagany format to XX-XXX .
                        </div>
                    </transition>
                </div>
                <div class="finalize__content__container__half --smallerHalf">
                    <div class="finalize__content__container__half__texts" v-if="step !== 2">
                        <div class="finalize__content__container__half__header">
                            <h4>Metoda Płatności</h4>
                        </div>
                        <div class="finalize__content__container__half__subheader">
                            <h3>Wybierz w jaki sposób chcesz zapłacić.</h3>
                        </div>
                    </div>
                    <div class="finalize__content__container__half__method" v-if="step !== 2">
                        <div class="finalize__content__container__half__method__option" @click="paymentPopup()">
                            <div class="finalize__content__container__half__method__option__image" v-if="paymentType.name !== 'Nie wybrano'">
                                <img loading="lazy" :src="require('../assets/' + paymentType.imgSource)">
                            </div>
                            <div class="finalize__content__container__half__method__option__name">
                                {{paymentType.name}}
                            </div>
                            <div class="finalize__content__container__half__method__option__cta">
                                Zmień
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    <transition name="fade">
                    <div class="finalize__content__container__half__texts" v-if="showDeliveryChoose && step !== 2">
                        <div class="finalize__content__container__half__header">
                            <h4>Sposób dostawy</h4>
                        </div>
                        <div class="finalize__content__container__half__subheader">
                            <h3>Wskaż sposób dostawy towaru.</h3>
                        </div>
                    </div>
                    </transition>
                    <transition name="fade">
                    <div class="finalize__content__container__half__method" v-if="showDeliveryChoose && step !== 2">
                        <div class="finalize__content__container__half__method__option" @click="deliveryPopup()">
                            <div class="finalize__content__container__half__method__option__image" v-if="deliveryType.name !== 'Nie wybrano'">
                                <img :src="require('../assets/' + deliveryType.imgSource)">
                            </div>
                            <div class="finalize__content__container__half__method__option__name">
                                {{deliveryType.name}} <span v-if="(deliveryType.name === 'Kurier DPD' || deliveryType.name === 'Kurier InPost') && totalPrice.toFixed(2)<=500"> {{'+' + (deliveryType.price*amountOfPackages).toFixed(2) + 'PLN'}} </span>
                                <span class="green" v-if="(deliveryType.name === 'Kurier DPD' || deliveryType.name === 'Kurier InPost') && totalPrice.toFixed(2)>=500 && this.paymentType.name !== 'Płatność u kuriera'"> Darmowa </span>
                            </div>
                            <div class="finalize__content__container__half__method__option__cta">
                                Zmień
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    </transition>
                    <div class="finalize__content__container__half__summary">
                        <div class="finalize__content__container__half__summary__box">
                            <div class="finalize__content__container__half__summary__box__title">
                                Suma
                                <span class="finalize__content__container__half__summary__box__title__discountUsed" v-if="this.showDiscount === false && this.discountValidation === true">Zastosowano kod <span>{{this.discountCode}}</span></span>
                            </div>
                            <div class="finalize__content__container__half__summary__box__price">
                                <span class="finalize__content__container__half__summary__box__price__total">{{(totalPrice+(deliveryType.price*amountOfPackages)-finalDiscountValue).toFixed(2)}} PLN</span>
                                <span class="finalize__content__container__half__summary__box__price__brutto">Brutto</span>
                            </div>
                            <div class="finalize__content__container__half__summary__box__discountInfo" v-if="this.discountValidation === true">
                                <div class="finalize__content__container__half__summary__box__discountInfo__row">
                                    <span>Cena przed rabatem: <span class="finalize__content__container__half__summary__box__discountInfo__row__amount">{{totalPrice.toFixed(2)}} PLN</span></span>
                                </div>
                                <div class="finalize__content__container__half__summary__box__discountInfo__row" v-if="deliveryType.price*amountOfPackages > 0">
                                    <span>Dostawa: <span class="finalize__content__container__half__summary__box__discountInfo__row__amount">{{(deliveryType.price*amountOfPackages).toFixed(2)}} PLN</span></span>
                                </div>
                                <div class="finalize__content__container__half__summary__box__discountInfo__row">
                                    <span>Naliczony rabat: <span class="finalize__content__container__half__summary__box__discountInfo__row__amount">-{{this.finalDiscountValue}} PLN</span></span>
                                </div>
                            </div>
                            <div class="finalize__content__container__half__summary__box__freeShip" v-if="(deliveryType.name === 'Kurier DPD' && totalPrice.toFixed(2)>=500 && this.paymentType.name !== 'Płatność u kuriera') || (deliveryType.name === 'Kurier InPost' && totalPrice.toFixed(2)>=500 && this.paymentType.name !== 'Płatność u kuriera') || (deliveryType.name.indexOf('Paczkomat Inpost') !== -1 && amountOfInpostPackages === 1 && totalPrice.toFixed(2)>=500)">
                                Darmowa dostawa
                            </div>
                            <div class="finalize__content__container__half__summary__box__goBack" v-if="step === 2" @click="previousStep()">Powrót do krok 1: Dane podstawowe</div>
                            <mainButton :class="{'disabled': paymentType.name === 'Nie wybrano' || deliveryType.name === 'Nie wybrano'}" :cta="buttonText" @clicked="checkForErrors()"></mainButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
        <div class="finalize__popup" v-if="togglePayment">
            <div class="finalize__popup__card">
                <div class="finalize__popup__card__content">
                    <div class="finalize__popup__card__content__method">
                        <div class="finalize__popup__card__content__method__option" @click="changePaymentType({imgSource: 'payuLogo.png', name: 'Płatność PayU'})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/payuLogo.png')" alt="Najtansze laptopy uzywane i komputery poleasingowe ze sklep komputerowy Kompre.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                                Płatność Online - PayU
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="finalize__popup__card__content__method">
                        <div class="finalize__popup__card__content__method__option" @click="changePaymentType({imgSource: 'przelewy24Logo.svg', name: 'Płatność Przelewy24'})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/przelewy24Logo.svg')" alt="Komputery poleasingowe na raty.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                                Płatność Online - Przelewy24
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="finalize__popup__card__content__method">
                        <div class="finalize__popup__card__content__method__option" @click="changePaymentType({imgSource: 'transfer.svg', name: 'Przelew tradycyjny'})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/transfer.svg')" alt="Tanie komputery do biura.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                                Przelew tradycyjny
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="finalize__popup__card__content__method">
                        <div class="finalize__popup__card__content__method__option" @click="changePaymentType({imgSource: 'money-min.png', name: 'Karta lub gotówka w punkcie'})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/money-min.png')" alt="Najtańsze monitory do komputera, komputer osobisty w dobrej cenie oraz uzywane komputery pc">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                                Karta lub gotówka w punkcie.
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="finalize__popup__card__content__method">
                        <div class="finalize__popup__card__content__method__option" @click="changePaymentType({imgSource: 'courier-min.png', name: 'Płatność u kuriera'})">
                            <div class="finalize__popup__card__content__method__option__image" >
                                <img loading="lazy" :src="require('../assets/courier-min.png')" alt="Komputery poleasingowe i nowe laptopy z outlet poleasingowy.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                                Płatność u kuriera.
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    <mainButton :cta="'Zamknij'" @clicked="paymentPopup()"></mainButton>
                </div>
            </div>
        </div>
        </transition>
        <transition name="fade">
        <div class="finalize__popup" v-if="toggleDelivery">
            <div class="finalize__popup__card">
                <div class="finalize__popup__card__content">
                    <div class="finalize__popup__card__content__method" v-if="paymentType.name !== 'Karta lub gotówka w punkcie' && paymentType.name !== 'Płatność u kuriera'">
                        <div class="finalize__popup__card__content__method__option" @click="changeDeliveryType({imgSource: 'dpdLogo.png', name: 'Kurier DPD', price: 16.50, point: null})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/dpdLogo.png')" alt="Najtansze laptopy uzywane i komputery poleasingowe ze sklep komputerowy Kompre.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                               <div>Kurier DPD</div>
                               <span v-if="totalPrice.toFixed(2)<=500">Dostawa kurierska - 16.50 PLN <span class="information">/paczka</span></span>
                               <span class="green" v-if="totalPrice.toFixed(2)>=500">Darmowa dostawa</span>
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>

                    <div class="finalize__popup__card__content__method" v-if="paymentType.name !== 'Karta lub gotówka w punkcie' && paymentType.name !== 'Płatność u kuriera'">
                        <div class="finalize__popup__card__content__method__option" @click="changeDeliveryType({imgSource: 'inPostCourier.png', name: 'Kurier InPost', price: 15.50, point: null})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/inPostCourier.png')" alt="Laptopy poleasingowe oraz uzywane monitory. Znajdz najtansze monitory do swojego komputera.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                               <div>Kurier InPost</div>
                               <span v-if="totalPrice.toFixed(2)<=500">Dostawa kurierska - 15.50 PLN <span class="information">/paczka</span></span>
                               <span class="green" v-if="totalPrice.toFixed(2)>=500">Darmowa dostawa</span>
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>

                    <div class="finalize__popup__card__content__method" v-if="paymentType.name !== 'Karta lub gotówka w punkcie' && paymentType.name === 'Płatność u kuriera'">
                        <div class="finalize__popup__card__content__method__option" @click="changeDeliveryType({imgSource: 'dpdLogo.png', name: 'Kurier DPD', price: 20.00, point: null})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/dpdLogo.png')" alt="Najtansze laptopy uzywane i komputery poleasingowe ze sklep komputerowy Kompre.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                               <div>Kurier DPD</div>
                               <span>Dostawa kurierska - 20.00 PLN <span class="information">/paczka</span></span>
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>

                    <div class="finalize__popup__card__content__method" v-if="paymentType.name !== 'Karta lub gotówka w punkcie' && paymentType.name === 'Płatność u kuriera'">
                        <div class="finalize__popup__card__content__method__option" @click="changeDeliveryType({imgSource: 'inPostCourier.png', name: 'Kurier InPost', price: 19.00, point: null})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/inPostCourier.png')" alt="Najtansze laptopy uzywane i komputery poleasingowe ze sklep komputerowy Kompre.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                               <div>Kurier InPost</div>
                               <span>Dostawa kurierska - 19.00 PLN <span class="information">/paczka</span></span>
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>

                    <div class="finalize__popup__card__content__method" v-if="(paymentType.name === 'Płatność PayU' || paymentType.name === 'Płatność Przelewy24' || this.paymentType.name === 'Przelew tradycyjny') && inPostAllowed === true">
                        <div class="finalize__popup__card__content__method__option" @click="openInpostModal()">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/inpostPaczkomatLogo.png')" alt="Najtansze laptopy uzywane i komputery poleasingowe ze sklep komputerowy Kompre.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                               <div>Paczkomaty InPost</div>
                               <span v-if="totalPrice.toFixed(2)<=500 || (totalPrice.toFixed(2)>=500 && amountOfInpostPackages > 1)">Odbiór w paczkomacie - {{(amountOfInpostPackages*11.99).toFixed(2)}} PLN</span>
                               <span class="green" v-if="totalPrice.toFixed(2)>=500 && amountOfInpostPackages === 1">Darmowa dostawa</span>
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="finalize__popup__card__content__method" v-if="paymentType.name === 'Karta lub gotówka w punkcie' || paymentType.name === 'Płatność PayU' || paymentType.name === 'Płatność Przelewy24' || this.paymentType.name === 'Przelew tradycyjny'">
                        <div class="finalize__popup__card__content__method__option" @click="changeDeliveryType({imgSource: 'building-min.png', name: 'Odbiór Wrocław', price: 0, point: null})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/building-min.png')" alt="Najtańsze monitory do komputera, komputer osobisty w dobrej cenie oraz uzywane komputery pc">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                                <div>Odbiór Wrocław</div>
                                <span>ul. Popowicka 28 (I piętro), Wrocław</span>
                                <span class="green">Darmowa dostawa</span>
                            </div>
                            
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    <div class="finalize__popup__card__content__method" v-if="paymentType.name === 'Karta lub gotówka w punkcie' || paymentType.name === 'Płatność PayU' || paymentType.name === 'Płatność Przelewy24' || this.paymentType.name === 'Przelew tradycyjny'">
                        <div class="finalize__popup__card__content__method__option" @click="changeDeliveryType({imgSource: 'warehouse-min.png', name: 'Odbiór Rogaszyce', price: 0, point: null})">
                            <div class="finalize__popup__card__content__method__option__image">
                                <img loading="lazy" :src="require('../assets/warehouse-min.png')" alt="Komputery poleasingowe i nowe laptopy z outlet poleasingowy.">
                            </div>
                            <div class="finalize__popup__card__content__method__option__name">
                                <div>Odbiór Rogaszyce</div>
                                <span>ul. Wesoła 37, Rogaszyce</span>
                                <span class="green">Darmowa dostawa</span>
                            </div>
                            <div class="finalize__popup__card__content__method__option__cta">
                                Wybierz
                                <i class="fi-rr-angle-small-right"></i>
                            </div>
                        </div>
                    </div>
                    <mainButton :cta="'Zamknij'" @clicked="deliveryPopup()"></mainButton>
                </div>
            </div>
        </div>
        </transition>
        <trust/>
        <ftr/>
    </div>
</template>
<script>
    // Imports
    import stepper from "../components/stepper.vue";
    import navbar from "../components/navbarStore.vue";
    import trust from "../components/trust.vue";
    import ftr from "../components/footer.vue";
    import cartEmptyState from "../components/emptyStateCart.vue";
    import productTile from "../components/productTile.vue";
    import mainButton from "../components/mainButton.vue";
    import suggestedProducts from "../components/suggestedProducts.vue";
    import loader from "../components/loader.vue";
    import inpostGeo from "../components/inpostModal.vue";
    import moment from "moment";
    import axios from "axios";
    import {
    db, firebase
    } from '../configs/firebase'
    

    export default {
        data() {
        return {
            showDiscount: false,
            discountOneUse: false,
            discountCode: "",
            discountValidation: false,
            discountError: false,
            discountType: "",
            discountCategory: [],
            discountValue: 0,
            finalDiscountValue: 0,
            discountNoUse: false,
            discountMinValue: 0,
            stepperStatus: ['completed','active','',''],
            editLoadedByNip: false,
            showLoadingNip: false,
            showDownloadedByNip: false,
            loading: false,
            step: 1,
            buttonText: 'Kontynuuj',
            stepText: "Etap 1 z 2: Dane podstawowe",
            companyMode: false,
            typeOfCustomer: "Zakup na firmę",
            rulesAccepted: true,
            togglePayment: false,
            toggleDelivery: false,
            showDeliveryChoose: false,
            paymentType: {
                imgSource: 'payuLogo.png',
                name: 'Nie wybrano'
            },
            deliveryType:
            {
                imgSource: 'dpdLogo.png',
                name: 'Nie wybrano',
                price: 0,
                amountOfPackages: 0,
                point: null
            },
            downloadedByNip: {
                address: "",
            },
            newOrder: {
                name: "",
                surname: "",
                mail: "",
                phone: "",
                company: "",
                taxNum: "",
                deliveryName: "",
                deliverySurname: "",
                address: "",
                city: "",
                postCode: "",
                comment: "",
                taxAddress: "",
                taxPostCode: "",
                taxCity: ""
            },
            errors: {
                name: false,
                surname: false,
                mail: false,
                company: false,
                taxNum: false,
                phone: false,
                rules: false,
                deliveryName: false,
                deliverySurname: false,
                address: false,
                city: false,
                postCode: false
            }
    
        }
        },
        methods: {
            countTotalPriceAfterDiscount(){
                for(let i=0; i < this.$store.state.cart.length; i++){
                    this.$store.state.cart[i].positionPrice = this.$store.state.cart[i].positionPrice - this.discountByItem[i]
                }
                // this.countTotalPrice();
            },
            // countTotalPrice(){
            //     let price = 0;
            //     for(let i=0; i<this.$store.state.cart.length; i++)
            //     {
            //         price += this.$store.state.cart[i].positionPrice*this.$store.state.cart[i].quantity;
            //     }
            //     this.totalPriceAfterDiscount = price;
            // },
            //      OLD VERSION DISCOUNT
            //     async useDiscountCode(){
            //     try{
            //         const result = await db.collection("Discounts").doc(this.discountCode).get();
            //         let item = result.data();
            //         if(item === undefined){
            //             this.discountError = true;
            //             this.discountValidation = false;
            //             this.finalDiscountValue = 0
            //             this.$store.commit('clearCode');
            //         }else{
            //             this.discountError = false;
            //             this.discountValidation = true;
            //             this.showDiscount = false;
            //             this.discountType = item.type
            //             this.discountCategory = item.category
            //             this.discountValue = item.value
            //             this.discountOneUse = item.oneUse
            //             this.discountMinValue = item.minValue
            //             let code = this.discountCode
            //             if(this.discountType === "percent"){
            //                 let discountItemsValue = 0
            //                 let discountAmount = 0
            //             for(let i=0; i < this.$store.state.cart.length; i++){
            //                 for(let y=0; y < this.discountCategory.length; y++){
            //                     if(this.$store.state.cart[i].product.category === this.discountCategory[y]){
            //                         let itemValue = this.$store.state.cart[i].positionPrice * this.$store.state.cart[i].quantity
            //                         let itemDiscount = (this.discountValue / 100) * itemValue
            //                         itemDiscount = Math.round(itemDiscount * 100) / 100
            //                         discountAmount = discountAmount + itemDiscount
            //                         discountItemsValue = discountItemsValue + itemValue
            //                     }
            //                 }
            //             }
            //             discountAmount = discountAmount.toFixed(2)
            //             if(discountAmount > 0 && discountItemsValue > this.discountMinValue){
            //                 this.finalDiscountValue = discountAmount
            //                 this.discountNoUse = false
            //                 this.$store.commit('addCode', code);
            //             }else{
            //                 this.showDiscount = true
            //                 this.discountValidation = false
            //                 this.discountError = false
            //                 this.discountType = ""
            //                 this.discountCategory = []
            //                 this.discountValue = 0
            //                 this.finalDiscountValue = 0
            //                 this.discountNoUse = true
            //                 this.discountOneUse = false
            //                 this.$store.commit('clearCode');
            //             }
            //             }
            //             if(this.discountType === "amount"){
            //                 let price = 0;
            //                 for(let i=0; i<this.$store.state.cart.length; i++)
            //                 {
            //                     for(let y=0; y < this.discountCategory.length; y++){
            //                         if(this.$store.state.cart[i].product.category === this.discountCategory[y]){
            //                             price += this.$store.state.cart[i].positionPrice*this.$store.state.cart[i].quantity;
            //                         }
            //                     }
            //                 }
            //                 let discountAmount = this.discountValue
            //             discountAmount = discountAmount.toFixed(2)
            //             if(discountAmount > 0 && discountAmount < price && price > this.discountMinValue){
            //                 this.finalDiscountValue = discountAmount
            //                 this.discountNoUse = false
            //                 this.$store.commit('addCode', code);
            //             }else{
            //                 this.showDiscount = true
            //                 this.discountValidation = false
            //                 this.discountError = false
            //                 this.discountType = ""
            //                 this.discountCategory = []
            //                 this.discountValue = 0
            //                 this.finalDiscountValue = 0
            //                 this.discountNoUse = true
            //                 this.discountOneUse = false
            //                 this.$store.commit('clearCode');
            //             }
            //             }

            //         }
            //     }
            //     catch(error)
            //     {
            //         console.log(error);
            //     }
            // },
                        async useDiscountCode(){
                try{
                    const result = await db.collection("Discounts").doc(this.discountCode).get();
                    let item = result.data();
                    if(item === undefined){
                        this.discountError = true;
                        this.discountValidation = false;
                        this.finalDiscountValue = 0
                        this.discountByItem = []
                        this.$store.commit('clearCode');
                    }else{
                        this.discountByItem = []
                        this.discountError = false;
                        this.discountValidation = true;
                        this.showDiscount = false;
                        this.discountType = item.type
                        this.discountCategory = item.category
                        this.discountProducts = item.products
                        this.discountValue = item.value
                        this.discountOneUse = item.oneUse
                        this.discountMinValue = item.minValue
                        let code = this.discountCode
                        if(this.discountType === "percent"){
                            let discountItemsValue = 0
                            let discountAmount = 0
                        for(let i=0; i < this.$store.state.cart.length; i++){
                            this.discountByItem.push(0)
                            if(this.discountCategory.length > 0){
                                for(let y=0; y < this.discountCategory.length; y++){
                                    if(this.$store.state.cart[i].product.category === this.discountCategory[y]){
                                        // console.log(this.$store.state.cart[i].product.uniqueID)
                                        // OLD DISCOUNT - BUGGED
                                        // let itemValue = this.$store.state.cart[i].positionPrice * this.$store.state.cart[i].quantity
                                        // let itemDiscount = (this.discountValue / 100) * itemValue
                                        // itemDiscount = Math.round(itemDiscount * 100) / 100
                                        // discountAmount = discountAmount + itemDiscount
                                        // discountItemsValue = discountItemsValue + itemValue

                                        let itemValue = this.$store.state.cart[i].positionPrice
                                        let itemDiscount = (this.discountValue / 100) * itemValue
                                        this.discountByItem[i] = itemDiscount
                                        discountItemsValue = discountItemsValue + (itemValue * this.$store.state.cart[i].quantity)
                                    }
                                }
                            }
                            if(this.discountProducts.length > 0){
                                for(let y=0; y < this.discountProducts.length; y++){
                                    if(this.$store.state.cart[i].product.uniqueID === this.discountProducts[y]){
                                        // console.log(this.$store.state.cart[i].product.uniqueID)
                                        // OLD DISCOUNT - BUGGED
                                        // let itemValue = this.$store.state.cart[i].positionPrice * this.$store.state.cart[i].quantity
                                        // let itemDiscount = (this.discountValue / 100) * itemValue
                                        // itemDiscount = Math.round(itemDiscount * 100) / 100
                                        // discountAmount = discountAmount + itemDiscount
                                        // discountItemsValue = discountItemsValue + itemValue

                                        let itemValue = this.$store.state.cart[i].positionPrice
                                        let itemDiscount = (this.discountValue / 100) * itemValue
                                        this.discountByItem[i] = itemDiscount
                                        discountItemsValue = discountItemsValue + (itemValue * this.$store.state.cart[i].quantity)
                                    }
                                }
                            }
                        }
                        for(let d=0; d < this.discountByItem.length; d++){
                            discountAmount = discountAmount + (this.discountByItem[d] * this.$store.state.cart[d].quantity)
                        }
                        discountAmount = discountAmount.toFixed(2)
                        if(discountAmount > 0 && discountItemsValue > this.discountMinValue){
                            this.finalDiscountValue = discountAmount
                            this.discountNoUse = false
                            this.$store.commit('addCode', code);
                        }else{
                            this.showDiscount = true
                            this.discountValidation = false
                            this.discountError = false
                            this.discountType = ""
                            this.discountCategory = []
                            this.discountByItem = []
                            this.discountValue = 0
                            this.finalDiscountValue = 0
                            this.discountNoUse = true
                            this.$store.commit('clearCode');
                        }
                        }
                        if(this.discountType === "amount"){
                            let price = 0;
                            let itemsToDiscount = 0
                            for(let i=0; i<this.$store.state.cart.length; i++)
                            {
                                this.discountByItem.push(0)
                                if(this.discountCategory.length > 0){
                                    for(let y=0; y < this.discountCategory.length; y++){
                                        if(this.$store.state.cart[i].product.category === this.discountCategory[y]){
                                            price += this.$store.state.cart[i].positionPrice*this.$store.state.cart[i].quantity;
                                            itemsToDiscount += this.$store.state.cart[i].quantity
                                            this.discountByItem[i] = "here"
                                        }
                                    }
                                }
                                if(this.discountProducts.length > 0){
                                    
                                    for(let y=0; y < this.discountProducts.length; y++){
                                        if(this.$store.state.cart[i].product.uniqueID === this.discountProducts[y]){
                                            price += this.$store.state.cart[i].positionPrice*this.$store.state.cart[i].quantity;
                                            itemsToDiscount += this.$store.state.cart[i].quantity
                                            this.discountByItem[i] = "here"
                                        }
                                    }
                                }
                            }
                        let discountAmount = this.discountValue
                        if(this.discountValue < price){
                        let toDiscount = discountAmount
                        let singleItemDiscount = discountAmount / itemsToDiscount
                        singleItemDiscount = Math.floor(singleItemDiscount * 1e2 ) / 1e2
                        let rest = discountAmount - (singleItemDiscount*itemsToDiscount)
                        rest = rest.toFixed(2);
                        rest = parseFloat(rest);
                        // console.log("reszta: " + rest)
                        // console.log("Single item discount: " + singleItemDiscount)
                        discountAmount = discountAmount.toFixed(2)
                        //pierwsze przejście
                        for(let d=0; d < this.discountByItem.length; d++){
                            if(this.discountByItem[d] === "here"){
                                if(this.$store.state.cart[d].positionPrice > singleItemDiscount){
                                    this.discountByItem[d] = singleItemDiscount
                                    toDiscount -= (this.discountByItem[d] * this.$store.state.cart[d].quantity)
                                }
                                if(this.$store.state.cart[d].positionPrice < singleItemDiscount){
                                    this.discountByItem[d] = this.$store.state.cart[d].positionPrice - 0.01
                                    toDiscount -= (this.discountByItem[d] * this.$store.state.cart[d].quantity)
                                    itemsToDiscount -= this.$store.state.cart[d].quantity
                                }
                                toDiscount = Math.round(toDiscount * 1e2 ) / 1e2
                                // console.log("Do odliczenia pozostało" + toDiscount)
                            }
                        }
               
                        // console.log("toDiscount przed whilem: " + toDiscount)
                        let newsingleItemDiscount = (toDiscount + rest) / itemsToDiscount
                        newsingleItemDiscount = newsingleItemDiscount.toFixed(2);
                        newsingleItemDiscount = parseFloat(newsingleItemDiscount);
                    
                        
                        // console.log("newSingle:" + newsingleItemDiscount)

                        // console.log('tyle zostało: ' +toDiscount);

                        while (toDiscount > 0) {
                            // console.log(itemsToDiscount/100)
                            // console.log("NEW DISCOUNT!!!!!!!!!!" + newsingleItemDiscount)
                            if(toDiscount > itemsToDiscount/100){
                                for(let o=0; o < this.discountByItem.length; o++){
                                    if(this.discountByItem[o] > 0 && this.discountByItem[o] !== this.$store.state.cart[o].positionPrice - 0.01){
                                        if(this.$store.state.cart[o].positionPrice > newsingleItemDiscount){
                                            // console.log("Przed" +this.discountByItem[o])
                                            this.discountByItem[o] = this.discountByItem[o] + newsingleItemDiscount
                                            this.discountByItem[o] = this.discountByItem[o].toFixed(2);
                                            this.discountByItem[o] = parseFloat(this.discountByItem[o]);
                                            // console.log("Po" + this.discountByItem[o])
                                            toDiscount -= (newsingleItemDiscount * this.$store.state.cart[o].quantity)
                                        }
                                        // console.log("Do odliczenia pozostało (while): " + toDiscount)
                                    }
                                }
                                newsingleItemDiscount = (toDiscount + rest) / itemsToDiscount
                                newsingleItemDiscount = newsingleItemDiscount.toFixed(2);
                                newsingleItemDiscount = parseFloat(newsingleItemDiscount);
                                // console.log("NEW DISCOUNT!!!!!!!!!!" + newsingleItemDiscount)
                            }else{
                                break
                            }
                        }
                        }
                        let totalDiscount = 0
                        for(let s=0; s < this.discountByItem.length; s++){
                            if(this.discountByItem[s] === "here"){
                                this.discountByItem[s] = 0
                            }else{
                            totalDiscount += this.discountByItem[s] * this.$store.state.cart[s].quantity
                            }
                            // console.log(s + ": " + this.discountByItem[s] * this.$store.state.cart[s].quantity)
                        }
                        totalDiscount = totalDiscount.toFixed(2)
                        totalDiscount = parseFloat(totalDiscount)


                        if(discountAmount > 0 && discountAmount < price && price > this.discountMinValue){
                            this.finalDiscountValue = totalDiscount
                            this.discountNoUse = false
                            this.$store.commit('addCode', code);
                        }else{
                            this.showDiscount = true
                            this.discountValidation = false
                            this.discountError = false
                            this.discountType = ""
                            this.discountCategory = []
                            this.discountProducts = []
                            this.discountByItem = []
                            this.discountValue = 0
                            this.finalDiscountValue = 0
                            this.discountNoUse = true
                            this.$store.commit('clearCode');
                        }
                        }

                    }
                }
                catch(error)
                {
                    console.log(error);
                }
            },
            async sendEmail()
            {
                const link = "https://europe-central2-kompreshop.cloudfunctions.net/webApi/sendEmail";
                axios.post(link)
            },
            openInpostModal()
            {
                this.toggleDelivery = false;
                window.scrollTo(0,0);
                document.getElementsByTagName('body')[0].style.overflowY = 'visible';
                easyPack.modalMap((point,modal)=>
                {
                    if(this.totalPrice >= 500 && this.amountOfInpostPackages === 1)
                    {
                        this.changeDeliveryType({imgSource: 'inpostPaczkomatLogo.png', name: `Paczkomat InPost ${point.name}`, price: 0, point: point});
                    }
                    else
                    {
                        this.changeDeliveryType({imgSource: 'inpostPaczkomatLogo.png', name: `Paczkomat InPost ${point.name}`, price: 11.99, point: point});
                    }
                    
                    modal.closeModal();
                }, { width: window.innerWidth, height: window.innerHeight})
            },
            changeCustomerType()
            {
                if(this.companyMode === false)
                {
                    this.typeOfCustomer = "Zakup na firmę";
                    this.editLoadedByNip =  false;
                    this.showLoadingNip =  false;
                    this.showDownloadedByNip = false;
                    this.newOrder.taxNum = "";
                    this.newOrder.taxAddress = "";
                    this.newOrder.taxPostCode = "";
                    this.newOrder.taxCity = "";
                    this.newOrder.company = "";
                }
                else
                {
                    this.typeOfCustomer = "Kupujesz na firmę.";
                }

            },
            editDataDownloadedByNip()
            {
                this.showDownloadedByNip = false;
                this.editLoadedByNip = true;
            },
            getDataByNIP()
            {
                if(/^[0-9]{10}$/.test(this.newOrder.taxNum))
                {
                    this.showLoadingNip = true;
                    let currentDate = moment(moment()).format("YYYY-MM-DD");
                    axios.get(`https://wl-api.mf.gov.pl/api/search/nip/${this.newOrder.taxNum}/?date=${currentDate}`)
                    .then((response)=>{
                        // handle success
                        setTimeout(()=>
                        {
                            this.showLoadingNip = false;
                            let subject = response.data.result.subject;
                            if(subject.residenceAddress !== null)
                            {
                                this.showDownloadedByNip = true;
                                this.downloadedByNip.address = subject.residenceAddress;
                                this.newOrder.taxAddress = subject.residenceAddress.split(",")[0];
                                this.newOrder.taxPostCode = subject.residenceAddress.split(",")[1].split(" ")[1];
                                this.newOrder.taxCity = subject.residenceAddress.split(",")[1].split(" ")[2];
                            }
                            if(subject.workingAddress !== null)
                            {
                                this.showDownloadedByNip = true;
                                this.downloadedByNip.address = subject.workingAddress;
                                this.newOrder.taxAddress = subject.workingAddress.split(",")[0];
                                this.newOrder.taxPostCode = subject.workingAddress.split(",")[1].split(" ")[1];
                                this.newOrder.taxCity = subject.workingAddress.split(",")[1].split(" ")[2];
                            }
                        },1000)
                    })
                    .catch((error)=>{
                        // handle error
                        this.showLoadingNip = false;
                        this.newOrder.taxAddress = subject.residenceAddress.split(",")[0];
                        this.newOrder.taxPostCode = subject.residenceAddress.split(",")[1];
                        this.newOrder.taxCity = subject.residenceAddress.split(",")[2];
                        console.log("Could not get by NIP.");
                    })

                }
                else
                {
                    this.showLoadingNip = false;
                    return 1;
                }
            },
            checkForErrors()
            {

                if(this.step === 1)
                {
                    // validate first form
                    if(this.companyMode)
                    {
                        if(this.newOrder.company.length>2)
                        {
                            this.errors.company = false;
                            if(/^[0-9]{10}$/.test(this.newOrder.taxNum))
                            {
                                this.errors.taxNum = false;
                            }
                            else
                            {
                                this.errors.taxNum = true;
                                return 0;
                            }
                        }
                        else
                        {
                            this.errors.company = true;
                            return 0;
                        }
                    }
                    if(this.newOrder.name.length>0)
                    {
                        this.errors.name = false;
                        
                        if(this.newOrder.surname.length>0)
                        {
                            this.errors.surname = false;

                            if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(this.newOrder.mail.trim()))
                            {
                                this.errors.mail = false;
                                if(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/.test(this.newOrder.phone.trim()))
                                {
                                    this.errors.phone = false;
                                    if(this.rulesAccepted)
                                    {
                                        this.errors.rules = false;
                                        this.changeStep();
                                    }
                                    else s
                                    {
                                        this.errors.rules = true;
                                        return 0;
                                    }
                                }
                                else
                                {
                                    this.errors.phone = true;
                                    return 0;
                                }
                            }
                            else
                            {
                                this.errors.mail = true;
                                return 0;
                            }
                        }
                        else
                        {
                            this.errors.surname = true;
                            return 0;
                        }
                    }
                    else
                    {
                        this.errors.name = true;
                        return 0;
                    }
                }
                else
                {
                    // validate delivery form
                    if(this.newOrder.deliveryName.length>0)
                    {
                        this.errors.deliveryName = false;
                        
                        if(this.newOrder.deliverySurname.length>0)
                        {
                            this.errors.deliverySurname = false;

                            if(this.newOrder.address.length>0)
                            {
                                this.errors.address = false;
                                if(this.newOrder.city.length>0)
                                {
                                    this.errors.city = false;
                                    if(/\d{2}-\d{3}/.test(this.newOrder.postCode))
                                    {
                                        this.errors.postCode = false;
                                        this.changeStep()
                                    } 
                                    else
                                    {
                                        this.errors.postCode = true;
                                        return 0;
                                    }
                                }
                                else
                                {
                                    this.errors.city = true;
                                    return 0;
                                }
                            }
                            else
                            {
                                this.errors.address = true;
                                return 0;
                            }
                        }
                        else
                        {
                            this.errors.deliverySurname = true;
                            return 0;
                        }
                    }
                    else
                    {
                        this.errors.deliveryName = true;
                        return 0;
                    }
                }
            },
            createBaselinkerProductList(cart,deliveryPrice)
            {
                // Create product list that is prepared for baselinker
                let baselinkerProducts = [];
                
                    // OLD VERSION DISCOUNT
                    // let toDiscount = 0
                    // let discountValue = this.finalDiscountValue
                    // toDiscount = discountValue
                for(let i=0; i<cart.length; i++)
                {
                    let currentItem = cart[i];
                    let addonString = '';


                    for(let s=0; s<currentItem.product.snippet.length; s++)
                    {
                       addonString = addonString + `${currentItem.product.snippet[s]} ` 
                    }


                    if(currentItem.product.specs.warrantyType.value.length>0)
                    {
                        addonString = addonString + " " + `Gw. ${currentItem.product.specs.warrantyType.value}`
                    }

                    if(currentItem.addons.length>0)
                    {
                        addonString = addonString + " Rozbudowa: "
                    }

                    for(let z=0; z<currentItem.addons.length;z++)
                    {
                        let addon = currentItem.addons[z];
                        //addonString = addonString + ` ${addon.addonName}: ${addon.optionName}`
                        addonString = addonString + `${addon.optionName} `
                    }
                    

                    let productScheme = {
                        name: `${currentItem.product.name}`,
                        storage: "db",
                        storage_id: 0,
                        product_id: `${currentItem.product.baselinkerProductID}`,
                        tax_rate: 23,
                        sku: `${currentItem.product.sku}`,
                        ean: `${currentItem.product.ean}`,
                        price_brutto: parseFloat(currentItem.positionPrice).toFixed(2),
                        attributes: addonString,
                        quantity: currentItem.quantity,
                        weight: 0.1
                    }
                    // console.log(productScheme)

                    baselinkerProducts.push(productScheme);
                }

                // OLD VERSION DISCOUNT
                // if(this.finalDiscountValue > 0){
                //     let discountScheme = {
                //         name: `Kod rabatowy: ${this.discountCode}`,
                //         storage: "db",
                //         storage_id: 0,
                //         product_id: ``,
                //         tax_rate: 23,
                //         sku: ``,
                //         ean: ``,
                //         price_brutto: parseFloat(this.finalDiscountValue).toFixed(2)*(-1),
                //         attributes: '',
                //         quantity: 1,
                //         weight: 0
                //     }

                //     baselinkerProducts.push(discountScheme);
                // }



                return baselinkerProducts;

            },
            createPayuProductList(cart,deliveryPrice)
            {
                // Create product list that is prepared for payu
                let payuProducts = [];
                
                for(let i=0; i<cart.length; i++)
                {
                    let currentItem = cart[i];
                    let addonString = "";

                    for(let z=0; z<currentItem.addons.length;z++)
                    {
                        let addon = currentItem.addons[z];
                        addonString = addonString + " + " + `${addon.addonName}: ${addon.optionName} `
                    }
                    
                    let productScheme = {
                        name: `${currentItem.product.name} (ID: ${currentItem.product.uniqueID})` + addonString,
                        unitPrice: Math.round(parseFloat(currentItem.positionPrice)*100),
                        quantity: currentItem.quantity
                    }

                    payuProducts.push(productScheme);
                }

                if(deliveryPrice>0)
                {
                    payuProducts.push({
                        name: `Usługa kurierska`,
                        unitPrice: deliveryPrice*100,
                        quantity: 1
                    });
                }


                return payuProducts;

            },
            previousStep()
            {
                // Previous step on cart
                this.loading = true;
                setTimeout(()=>
                {
                    this.stepperStatus = ['completed','active','',''];
                    window.scrollTo(0,0);
                    this.step = 1;
                    this.stepText = "Etap 1 z 2: Dane podstawowe";
                    this.buttonText = "Kontynuuj"
                    this.loading = false;
                },500)

            },
            async changeStep()
            {
                Object.keys(this.newOrder).forEach(prop => this.newOrder[prop] = this.newOrder[prop].trim());
                window.scrollTo(0,0);
                if((this.step === 1 && this.deliveryType.name === "Kurier DPD") || (this.step === 1 && this.deliveryType.name === "Kurier InPost"))
                {
                    this.stepperStatus = ['completed','completed','active',''];
                    this.loading = true;
                    this.stepText = "Etap 2 z 2: Dane dostawy";
                    this.buttonText = "Zakończ"
                    this.step = 2;
                    setTimeout(()=>
                    {
                        this.loading = false;
                    },1000)
                }
                else
                {
                    if(this.finalDiscountValue > 0){
                        this.countTotalPriceAfterDiscount();
                    }
                    let productsData = []
                    for(let i=0; i<this.$store.state.cart.length; i++){
                        let product = {
                            id: "",
                            quantity: null
                        }
                        product.id = this.$store.state.cart[i].product.uniqueID
                        product.quantity = this.$store.state.cart[i].quantity
                        productsData.push(product)
                    }
                        
                    if((this.totalPrice>=500 && this.deliveryType.name === 'Kurier DPD' && this.paymentType.name !== "Płatność u kuriera") || (this.totalPrice>=500 && this.deliveryType.name === 'Kurier InPost'  && this.paymentType.name !== "Płatność u kuriera"))
                    {
                        this.deliveryType.price = 0;
                    }
                    this.loading = true;
                    let onlinePayment = false;
                    if(this.paymentType.name === "Płatność PayU" || this.paymentType.name === "Płatność Przelewy24" || this.paymentType.name === 'Przelew tradycyjny')
                    {
                        onlinePayment = true;
                    }
                    let baselinkerProducts = this.createBaselinkerProductList(this.$store.state.cart,(this.deliveryType.price*this.deliveryType.amountOfPackages));
                    let payuProducts = this.createPayuProductList(this.$store.state.cart,(this.deliveryType.price*this.deliveryType.amountOfPackages));
                    let preparedOrder =                     
                    {
                        cart: this.$store.state.cart,
                        productsPreparedForPayU: payuProducts,
                        productsPreparedForBaselinker: baselinkerProducts,
                        currency: "PLN",
                        // OLD VERSION DISCOUNT
                        // totalPrice: this.totalPrice+(this.deliveryType.price*this.deliveryType.amountOfPackages)-this.finalDiscountValue,
                        totalPrice: this.totalPrice+(this.deliveryType.price*this.deliveryType.amountOfPackages),
                        // OLD VERSION DISCOUNT
                        // totalPricePayU: this.totalPrice*100+((this.deliveryType.price*100)*this.deliveryType.amountOfPackages)-this.finalDiscountValue*100,
                        totalPricePayU: this.totalPrice*100+((this.deliveryType.price*100)*this.deliveryType.amountOfPackages),
                        onlinePayment: onlinePayment,
                        deliveryPrice: this.deliveryType.price*this.deliveryType.amountOfPackages,
                        deliveryName: this.deliveryType.name,
                        pickupPointID: this.deliveryType.point !== null ? this.deliveryType.point.name : "",
                        pickupPointName: this.deliveryType.point !== null ? `Paczkomat InPost ${this.deliveryType.point.name}` : "",
                        pickupPointAddress: this.deliveryType.point !== null ? this.deliveryType.point.address.line1 : "",
                        pickupPointPostCode: this.deliveryType.point !== null ? this.deliveryType.point.address_details.post_code : "",
                        pickupPointCity: this.deliveryType.point !== null ? this.deliveryType.point.address_details.city : "",
                        paymentName: this.paymentType.name,
                        rulesAccepted: this.rulesAccepted,
                        isCompany: this.companyMode,
                        clientCredentials: this.newOrder,
                        firestoreID: "Unknown",
                        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                        discountCode: this.discountCode.length > 0 ? this.discountCode : ""
                    }
                    console.log(this.newOrder);

                    db.collection("Orders").add(preparedOrder)
                    .then((docRef) => {
                        preparedOrder.firestoreID = docRef.id;
                        docRef.update(preparedOrder).then((updatedDoc)=>
                        {
                            if(this.paymentType.name === "Płatność PayU")
                            {
                                // Payment
                                let testLink = "http://localhost:5001/kompreshop/europe-central2/webApi/payment";
                                let productionLink = "https://europe-central2-kompreshop.cloudfunctions.net/webApi/payment";
                                axios.post(productionLink, preparedOrder)
                                .then((response) => {
                                    if(response.data.status === true)
                                    {
                                        axios.post("https://europe-central2-kompreshop.cloudfunctions.net/webApi/UpdateShopStock", productsData)
                                        if(this.discountCode.length > 0){
                                            if(this.discountOneUse === true){
                                                db.collection("Discounts").doc(this.discountCode).delete();
                                            }else{
                                                db.collection('Discounts').doc(this.discountCode).update({
                                                    'uses':  firebase.firestore.FieldValue.increment(1)
                                                });
                                            }
                                        }
                                        this.$store.commit('reset');
                                        window.location.href = response.data.url;
                                    }
                                    else
                                    {
                                        console.log(response);
                                        alert("Problem w przetworzeniu płatności. Jeśli nie jesteś w stanie kontynuować poinformuj dział obsługi klienta pod numerem: +48 510 188 531 lub wybierz opcję za pobraniem. Przepraszamy.");
                                    }                                                          
                                })
                                .catch((error) => {
                                    console.log("Error with webApi:", error);
                                });
                                
                            }else
                            {
                                // Baselinker API
                                let testLink = "http://localhost:5001/kompreshop/europe-central2/webApi/blAddOrder";
                                let productionLink = "https://europe-central2-kompreshop.cloudfunctions.net/webApi/blAddOrder";
                                if(this.paymentType.name === "Przelew tradycyjny"){
                                    axios.post(productionLink,preparedOrder)
                                    .then(async (response) => {
                                        await db.collection("Orders").doc(preparedOrder.firestoreID).update({"baselinkerID": response.data.order_id}).catch((error)=>
                                        {
                                            console.log(error);
                                        })
                                        if(response.data.status === "SUCCESS")
                                        {
                                            axios.post("https://europe-central2-kompreshop.cloudfunctions.net/webApi/UpdateShopStock", productsData)
                                            if(this.discountCode.length > 0){
                                                if(this.discountOneUse === true){
                                                    db.collection("Discounts").doc(this.discountCode).delete();
                                                }else{
                                                    db.collection('Discounts').doc(this.discountCode).update({
                                                        'uses':  firebase.firestore.FieldValue.increment(1)
                                                    });
                                                }
                                            }
                                            this.$store.commit('reset');
                                            this.$router.push(`/podziekowanie?type=transfer&id=${docRef.id}`);
                                        }
                                        else
                                        {
                                            this.$router.push("/podziekowanie?error=501")
                                        }
                                                            
                                    })
                                    .catch((error) => {
                                        console.log("Error with webApi:", error);
                                    });
                                }
                                if(this.paymentType.name === "Płatność Przelewy24" || this.paymentType.name === "Karta lub gotówka w punkcie" || this.paymentType.name === "Płatność u kuriera"){
                                axios.post(productionLink,preparedOrder)
                                .then(async (response) => {

                                    if(response.data.status === "SUCCESS")
                                    { 
                                        if(this.paymentType.name === "Płatność Przelewy24")
                                        {
                                            // let link = "https://europe-central2-kompreshop.cloudfunctions.net/webApi/payment/przelewy24";
                                            let link = "https://europe-central2-kompreshop.cloudfunctions.net/webApi/payment/przelewy24";
                                            db.collection("Orders").doc(preparedOrder.firestoreID).update({"baselinkerID": response.data.order_id}).catch((error)=>
                                            {
                                                console.log(error);
                                            })
                                            let p24result = await axios.post(link, preparedOrder);
                                            if(p24result.data.status === "ok")
                                            {
                                                axios.post("https://europe-central2-kompreshop.cloudfunctions.net/webApi/UpdateShopStock", productsData)
                                                if(this.discountCode.length > 0){
                                                    if(this.discountOneUse === true){
                                                        db.collection("Discounts").doc(this.discountCode).delete();
                                                    }else{
                                                        db.collection('Discounts').doc(this.discountCode).update({
                                                            'uses':  firebase.firestore.FieldValue.increment(1)
                                                        });
                                                    }
                                                }
                                                this.$store.commit('reset');
                                                window.location.href = p24result.data.url;
                                            }
                                            else
                                            {
                                                this.$router.push("/podziekowanie?error=501")
                                            }
                                        }
                                        else
                                        {
                                            axios.post("https://europe-central2-kompreshop.cloudfunctions.net/webApi/UpdateShopStock", productsData)
                                            if(this.discountCode.length > 0){
                                                if(this.discountOneUse === true){
                                                    db.collection("Discounts").doc(this.discountCode).delete();
                                                }else{
                                                    db.collection('Discounts').doc(this.discountCode).update({
                                                        'uses':  firebase.firestore.FieldValue.increment(1)
                                                    });
                                                }
                                            }
                                            this.$store.commit('reset');
                                            this.$router.push("/podziekowanie");
                                        }
                                    }
                                    else
                                    {
                                        this.$router.push("/podziekowanie?error=501")
                                    }
                                                        
                                })
                                .catch((error) => {
                                    console.log("Error with webApi:", error);
                                });
                            }

                            }

                           
                        }).catch((error)=>
                        {
                            console.error("Error updating orderID: ", error);
                        })
                    })
                    .catch((error) => {
                        console.error("Error creating order: ", error);
                    });

                }
            },
            changePaymentType(payment)
            {
                this.paymentType = payment;
                this.showDeliveryChoose = true;
                this.deliveryType = {
                    imgSource: 'dpdLogo.png',
                    name: 'Nie wybrano',
                    price: 0
                }
                this.paymentPopup();
            },
            changeDeliveryType(delivery)
            {
                this.deliveryType = delivery;
                if((this.totalPrice>=500 && this.deliveryType.name === 'Kurier DPD' && this.paymentType.name !== "Płatność u kuriera") || (this.totalPrice>=500 && this.deliveryType.name === 'Kurier InPost' && this.paymentType.name !== "Płatność u kuriera"))
                {
                    this.deliveryType.price = 0;
                }
                                
                if(this.deliveryType.name === "Kurier DPD" || this.deliveryType.name === "Kurier InPost")
                {
                    this.stepText = "Etap 1 z 2: Dane podstawowe";
                    this.buttonText = "Kontynuuj"
                }
                else
                {
                    this.stepText = "Etap 1 z 1: Dane podstawowe";
                    this.buttonText = "Zakończ"
                }

                if(this.deliveryType.name.indexOf("Paczkomat InPost") === -1)
                {
                    this.deliveryPopup();
                }
            },
            paymentPopup()
            {
                if(this.togglePayment === false)
                {
                    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                    this.togglePayment = true;
                    window.scrollTo(0,0);

                }
                else
                {
                    document.getElementsByTagName('body')[0].style.overflowY = 'visible';
                    this.togglePayment = false;
                    window.scrollTo(0,0);

                }
            },
            deliveryPopup()
            {
                this.togglePayment = false;

                if(this.toggleDelivery === false)
                {
                    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                    window.scrollTo(0,0);
                    this.toggleDelivery = true;
                }
                else
                {
                    this.toggleDelivery = false;
                    window.scrollTo(0,0);
                    document.getElementsByTagName('body')[0].style.overflowY = 'visible';
                }
            }
        },
        computed: {
            inPostAllowed()
            {
                let isAllowed = true;
                for(let i=0;i<this.$store.state.cart.length;i++)
                {
                    if(this.$store.state.cart[i].product.inpost.allowed === false)
                    {
                        isAllowed = false;
                    }
                }

                return isAllowed;
            },
            cart()
            {
                return this.$store.state.cart;
            },
            totalPrice()
            {
                let price = 0;
                for(let i=0; i<this.$store.state.cart.length; i++)
                {
                    price += this.$store.state.cart[i].positionPrice*this.$store.state.cart[i].quantity;
                }
                return price;
            },
            amountOfInpostPackages()
            {
                    let packages = 0;
                    for(let i=0; i<this.$store.state.cart.length; i++)
                    {
                        let currentItem = this.$store.state.cart[i];
                        currentItem.inpostCap !== null ? packages = packages + (currentItem.inpostCap*currentItem.quantity) : packages = packages + 0;
                    }
                    
                    packages = Math.ceil(packages);

                    return packages;
            },
            amountOfPackages()
            {
                let packages = 0;
                if(this.deliveryType.name === 'Kurier DPD' || this.deliveryType.name === 'Kurier InPost')
                {
                    let maxWeightPerPackage = 25;
                    let totalWeight = 0;
                    packages = 0;
                    for(let i=0; i<this.$store.state.cart.length; i++)
                    {
                        let currentItem = this.$store.state.cart[i];
                        if(currentItem.courierWeight === null)
                        {
                            currentItem.courierWeight = 1;
                        }
                        totalWeight = totalWeight + (currentItem.courierWeight*currentItem.quantity);
                    }
                    
                    packages = Math.ceil(totalWeight/maxWeightPerPackage)
                    this.deliveryType.amountOfPackages = packages; 
                }
                else
                {
                    packages = 0;
                    for(let i=0; i<this.$store.state.cart.length; i++)
                    {
                        let currentItem = this.$store.state.cart[i];
                        currentItem.inpostCap !== null ? packages = packages + (currentItem.inpostCap*currentItem.quantity) : packages = packages + 0;
                    }
                    packages = Math.ceil(packages);
                    this.deliveryType.amountOfPackages = packages;
                }

                return packages;
            }
        },
        mounted()
        {
            // console.log(this.$store.state.cart)
            if(this.$store.state.promoCode.length > 0){
                this.discountCode = this.$store.state.promoCode
                this.useDiscountCode();
            }
            if(this.$store.state.cart.length === 0){
                this.$router.push("/")
            }
            easyPack.init({
                defaultLocale: 'pl',
                langSelection: false,
                points: {
                    types: ['parcel_locker']
                },
                map: {
                    defaultLocation: [51.507351, -0.127758],
                    initialTypes: ['parcel_locker']
                },
                mapType: 'google',
                searchType: 'google',
                map: {
                    googleKey: 'AIzaSyCaLk-lBouaQ4vDEN8qniLiHE3fIqHmb3U'
                }
            });
            window.scrollTo(0,0);
        },
        components: {navbar,trust,ftr,cartEmptyState,mainButton,suggestedProducts,productTile,loader,stepper,inpostGeo},
    }
</script>
<style lang="scss" scoped>
    @import '../styles/variables';

    .information{
        opacity: 0.5;
        font-size: 0.64rem !important;
    }

    .additionalComment
    {
        font-size: 0.64rem;
        color: $gray;
        margin-top: 0.64rem;
        
    }

    .switch {
    display: inline-block;
    position: relative;
    width: 60px;
    height: 34px;
    }

    .slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #ccc;
    cursor: pointer;
    transition: all 0.4s ease;
    }

    .slider::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 26px;
    height: 26px;
    background-color: white;
    transition: all 0.4s ease;
    }

    .switch input[type="checkbox"]:checked + .slider {
    background-color: turquoise;
    }

    .switch input[type="checkbox"]:checked + .slider::before {
    transform: translateX(26px);
    }

    /* Round */
    .round {
    border-radius: 34px;
    }

    .round::before {
    border-radius: 50%;
    }



    h1,h4 {
        text-align: left;
        user-select: none;
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif !important;
        font-weight: 500;
        font-size: 1.25rem;
    }

    h3
    {
        text-align: left;
        font-weight: 400;
        padding: 0;
        font-size: 1rem;
        margin: 0;
    }

    .finalize
    {
        display: flex;
        width: 100vw;
        flex-direction: column;
        margin-top: 10.527em;

        &__popup
        {
            top:0;
            position: fixed;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100vw;
            height: 100vh;
            z-index: 101000;
            background: rgba(0, 0, 0, 0.7 );

            &__inpostCard
            {
                background-color: white;
                width: 95%;
            }

            &__card
            {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2em;
                width: 40%;
                min-height: 90%;
                border-radius: 5px;
                background: white;
                box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);

                &__content
                {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 90%;

                    &__method
                    {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin-bottom: 1rem;

                        &__option
                        {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 100%;
                            min-height: 4.768rem;
                            border: 1px solid $gray;
                            box-shadow: 0 1px 3px rgba(0,0,0,0.03), 0 1px 2px rgba(0,0,0,0.06);
                            border-radius: 5px;
                            transition: all 0.4s ease-in;
                            opacity: 0.8;

                            &__image
                            {
                                width: 15%;
                                height: 4.768rem;
                                padding-top: 0.41rem;
                                padding-bottom: 0.41rem;
                                margin-left: 1rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img
                                {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }

                            &__cta
                            {
                                display: flex;
                                align-items: center;
                                color: $red;
                                font-size: 1rem;
                                transition: all 0.4s ease-in;
                                margin-left: auto;
                                margin-right: 1rem;
                            }

                            &__cta:hover
                            {
                                color: $redHover;
                            }

                            &__name 
                            {
                                font-weight: 500;
                                display: flex;
                                flex-direction: column;
                                
                                margin-left: 1rem;

                                span
                                {
                                    color: $gray;
                                    font-size: 0.8rem;
                                }
                            }

                        }

                        &__option:hover
                        {
                            cursor: pointer;
                            opacity: 1;
                            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                        }
                        



                    }


                    &__button
                    {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }
            }
        }

        &__content
        {
            display: flex;
            flex-direction: column;
            width: 90%;
            padding-left: 5%;
            padding-right: 5%;

            


            &__container
            {
                display: flex;
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;

                &__half
                {
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                
                &__error
                {
                    color: $red;
                    margin-top: 0.8rem;
                    font-size: 0.8rem;
                }

                &__summary
                {
                width: 100%;
                display: flex;
                align-items: center;

                &__box
                {
                    
                    margin-bottom: 1.25rem;
                    margin-left: auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    &__discountInfo{
                        &__row{
                            display: flex;
                            span{
                                font-size: 0.8rem;
                                font-weight: 400;
                            }
                            &__discountAmount{
                                color: $red;
                                font-weight: 500 !important;
                            }
                            &__amount{
                                font-weight: 500 !important;
                            }
                        }
                    }
                    &__freeShip
                    {
                        color: #4AD395;
                    }

                    &__goBack
                    {

                        display: flex;
                        justify-content: center;
                        margin-top: 1.25rem;
                        cursor: pointer;
                        opacity: 0.7;
                        color: $gray;
                        transition: all 0.4s ease-in;
                    }

                    &__goBack:hover
                    {
                        opacity: 1;
                        text-decoration: underline;
                    }

                    &__red
                    {
                        margin-top: 0.64rem;
                        font-size: 0.8rem;
                        color: $red;
                    }

                    &__title
                    {
                        display: flex;
                        align-items: center;
                        color: $gray;
                        font-weight: 500;
                        padding-bottom: 0.8rem;
                        border-bottom: 1px solid $border;
                        &__discountUsed{
                            display: flex;
                            align-items: center;
                            font-size: 0.8rem;
                            color: #000000;
                            font-weight: 400;
                            margin-left: auto;
                            cursor: auto;
                            transition: all 0.2s ease-in-out;
                            span{
                                font-weight: 500;
                                color: $red;
                                margin-left: 0.21rem;
                            }
                            
                        }
                    }

                    &__price
                    {
                        display: flex;
                        align-items: center;
                        padding-top: 0.8rem;
                        font-size: 1.953rem;

                        &__brutto
                        {
                            margin-left: 0.8rem;
                            font-size: 1rem;
                            color: $gray;
                        }

                    }
                }
            }

                    &__method
                    {
                        width: 100%;
                        display: flex;
                        align-items: center;
                        margin-bottom: 1.25rem;
                        

                        &__option
                        {
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            width: 100%;
                            height: 5.96rem;
                            border: 1px solid $gray;
                            box-shadow: 0 1px 3px rgba(0,0,0,0.03), 0 1px 2px rgba(0,0,0,0.06);
                            border-radius: 5px;
                            transition: all 0.4s ease-in;
                            opacity: 0.8;

                            &__name 
                            {
                                font-weight: 500;
                                display: flex;
                                align-items: center;
                                margin-left: 1rem;

                                span
                                {
                                    color: $gray;
                                    margin-left: 1rem;
                                }
                            }

                            &__image
                            {
                                width: 20%;
                                height: 5.96rem;
                                margin-left: 1rem;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                
                                img
                                {
                                    max-width: 90%;
                                    max-height: 90%;
                                }
                            }

                            &__cta
                            {
                                display: flex;
                                align-items: center;
                                color: $red;
                                transition: all 0.4s ease-in;
                                margin-left: auto;
                                margin-right: 1rem;
                            }

                            &__cta:hover
                            {
                                color: $redHover;
                            }
                        }

                        &__option:hover
                        {
                            cursor: pointer;
                            opacity: 1;
                            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
                        }

                    }

                    &__rules
                    {
                        display: flex;
                        align-items: center;
                        margin-top: 1.25rem;
                        user-select: none;

                        input[type="checkbox"]
                        {
                            margin-right: 1rem;
                        }

                        input[type="checkbox"]:hover
                        {
                            cursor: pointer;
                        }

                        a
                        {
                            margin-left: 0.41rem;
                            margin-right: 0.41rem;
                            color: $red;
                            border-bottom: 1px solid $red;
                            transition: all 0.4s ease-in;
                        }

                        a:hover
                        {
                            color: $redHover;
                            border-bottom: 1px solid $redHover;
                        }

                    }

                    &__inputSection
                    {
                        display: flex;
                        flex-direction: column;
                        width: 60%;
                        margin-top: 1.25rem;
                        user-select: none;

                        &__sectionName
                        {
                            font-weight: 500;
                        }
                    }

                    &__texts
                    {
                        margin-bottom: 1.25rem;
                    }

                    &__isCompany
                    {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        user-select: none;

                        &__checkContainer
                        {
                            display: flex;
                            align-items: center;
                            margin-right: 1.953rem;
                        }

                        
                    }
                }
            }

            &__breadcrumbs
            {
                width: 100%;
                font-size: 0.8rem;
                color: $gray;
                display: flex;
                align-items: center;
                user-select: none;
                margin-bottom: 0.64rem;

                i
                {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-left: 0.125rem;
                    padding-right: 0.125rem;
                }

                a:hover
                {
                    color: $red;
                }
            }
        }
    }

    .--smallerHalf
    {
        width: 30%;
    }

    input[type="text"]
    {
        width: 70%;
        padding: 0.8rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        outline: none;
        margin-top: 0.328rem;
        font-size: 1rem;
        border: 1px solid $gray;
        border-radius: 3px;
        font-weight: 500;
        box-shadow: 0 1px 3px rgba(0,0,0,0.03), 0 1px 2px rgba(0,0,0,0.06);
        opacity: 0.7;
        transition: all 0.4s ease-in;
    }

    input[type="text"]::placeholder
    {
        color: $gray;
        opacity: 0.4;
        font-size: 1rem;
    }

    input[type="text"]:hover
    {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        opacity: 1;
    }

    input[type="text"]:focus
    {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        opacity: 1;
    }


    .fade-enter-active, .fade-leave-active {
    transition: opacity .6s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transition: opacity .3s;
    }

    .disabled
    {
        opacity: 0.4;
        pointer-events: none !important;
    }

    @media (max-width: 1024px)
    {
        input[type="text"]
        {
            width: 100%;
        }

        .finalize
        {
            margin-top: 6.125em;  
            &__popup
            {
                
                &__card
                {
                    width: 90%;
                }
            }

            &__content
            {
                width: 90%;
                
                &__breadcrumbs
                {
                    display: none;
                    width: 100%;
                }

                &__container
                {
                    padding: 0;
                    width: 100%;
                    flex-direction: column;

                    &__half
                    {
                        margin: 0;
                        padding: 0;
                        width: 100% !important;
                        margin-bottom: 1.563rem;

                        &__inputSection
                        {
                            
                            width: 90%;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 769px) {
    .finalize{
        margin-top: 4.768em;
    }
  }

@media (max-height: 665px)
{
.finalize__popup__card__content__method__option{
    height: 3.815rem;
    }
}
@media (max-height: 550px)
{
.finalize__popup__card__content__method__option{
    height: 3.052rem;
    }
}

.MuiCircularProgress-root{display:inline-block; margin-right: 0.64rem;}.MuiCircularProgress-static{transition:transform .3s cubic-bezier(.4,0,.2,1) 0s}.MuiCircularProgress-indeterminate{animation:MuiCircularProgress-keyframes-circular-rotate 1.4s linear infinite}.MuiCircularProgress-determinate{transition:transform .3s cubic-bezier(.4,0,.2,1) 0s}.MuiCircularProgress-colorPrimary{color:#3f51b5}.MuiCircularProgress-colorSecondary{color:#f50057}.MuiCircularProgress-svg{display:block;animation:loading_animation 1.4s infinite}.MuiCircularProgress-circle{stroke:currentColor}.MuiCircularProgress-circleStatic{transition:stroke-dashoffset .3s cubic-bezier(.4,0,.2,1) 0s}.MuiCircularProgress-circleIndeterminate{animation:MuiCircularProgress-keyframes-circular-dash 1.4s ease-in-out infinite;stroke-dasharray:80px,200px;stroke-dashoffset:0}.MuiCircularProgress-circleDeterminate{transition:stroke-dashoffset .3s cubic-bezier(.4,0,.2,1) 0s}@keyframes MuiCircularProgress-keyframes-circular-rotate{0%{transform-origin:50% 50%}100%{transform:rotate(360deg)}}@keyframes MuiCircularProgress-keyframes-circular-dash{0%{stroke-dasharray:1px,200px;stroke-dashoffset:0}50%{stroke-dasharray:100px,200px;stroke-dashoffset:-15px}100%{stroke-dasharray:100px,200px;stroke-dashoffset:-125px}}.MuiCircularProgress-circleDisableShrink{animation:none}@keyframes loading_animation{0%{color:$gray}25%{color:$red}50%{color:#f9bb2d}75%{color:$redHover}}

.downloadingData
{
    display: flex;
    align-items: center;
    font-size: 0.64rem;
    font-weight: 500;
    color: $gray;
    margin-top: 0.64rem;

    &__text
    {
        opacity: 0.7;
    }
}

.addressTitle
{
    font-weight: 500;
    font-size: 0.8rem;
}

.addressValue
{
    font-size: 0.8rem;
}

.editValue
{
    color: $red;
    text-decoration: underline;
    transition: all 0.4s ease-in;
    cursor: pointer;
}

.editValue:hover
{

    color: $redHover;
}

.green
{
    color: #4AD395 !important;
}

    

</style>